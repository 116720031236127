<template>
  <div>
    <div class="bill-title">仓库盘点</div>
    <el-form
      :model="form"
      status-icon
      :rules="rules"
      ref="form"
      label-width="70px"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="编号" prop="billCode">
            <el-input v-model="form.billCode" placeholder="编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="日期" prop="billDate">
            <el-date-picker
              @change="getBillCode(5)"
              v-model="form.billDate"
              placeholder="日期"
              :clearable="false"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="仓库" prop="storeId">
            <BaseSelect
              info="Store"
              v-model="form.storeId"
              @input="setStoreArray()"
            ></BaseSelect>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="包含零库存" label-width="90px">
            <el-switch v-model="showAll" @change="setStoreArray()"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="备注">
            <el-input
              v-model="form.remark"
              placeholder="备注"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="附件">
            <BillAttach :attachId="form.attachId"></BillAttach>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-input
          v-model="filter"
          placeholder="输入关键字搜索"
          clearable
          size="mini"
          style="width: 200px"
        ></el-input>
      </el-form-item>
      <div style="margin: -8px 0px 8px 60px">
        <el-table
          :data="array"
          :row-class-name="tableRowClassName"
          stripe
          border
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            label="编号"
            prop="goodsCode"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="名称"
            prop="goodsName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="规格"
            prop="goodsSpec"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="单位"
            prop="goodsUnit"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="系统数量"
            prop="oldQuantity"
          ></el-table-column>
          <el-table-column label="实际数量">
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.quantity"
                @mousewheel.native.prevent
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="盈亏" show-overflow-tooltip>
            <template slot-scope="scope">{{
              (Number(scope.row.quantity) - scope.row.oldQuantity) | thousands
            }}</template>
          </el-table-column>
        </el-table>
      </div>
      <el-form-item>
        <el-button-group>
          <el-button type="info" @click="save">保存</el-button>
          <el-button type="warning" @click="cancel">关闭</el-button>
        </el-button-group>

        <span>
          共计盘点
          <el-tag type="success">{{ totalCount }}</el-tag
          >条
        </span>
      </el-form-item>
      <el-form-item>
        <operator-box :item="form"></operator-box>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect.vue";
import billcode from "@/common/billcodeMixins.js";
import OperatorBox from "@/components/OperatorBox";
import guid from "@/common/guid.js";
import BillAttach from "@/components/BillAttach";
export default {
  mixins: [billcode],
  components: {
    BaseSelect,
    OperatorBox,
    BillAttach,
  },
  data() {
    return {
      form: {},
      rules: {
        billCode: [this.$g.required],
        billDate: [this.$g.required],
        storeId: [this.$g.required],
      },
      filter: "",
      showAll: false,
      arrayCopy: [],
    };
  },
  computed: {
    array() {
      let val = this.filter;
      if (val) {
        return this.arrayCopy.filter((item) => {
          if (
            item.goodsName.indexOf(val) >= 0 ||
            item.pinYin.indexOf(val.toLowerCase()) >= 0 ||
            item.goodsCode.indexOf(val) >= 0
          ) {
            return true;
          }
        });
      } else {
        return this.arrayCopy;
      }
    },
    totalCount() {
      let res = 0;
      this.arrayCopy.forEach((item) => {
        if (Number(item.quantity) - item.oldQuantity != 0) {
          res += 1;
        }
      });
      return res;
    },
  },
  mounted() {
    this.form = {
      billCode: "",
      billDate: new Date(),
      storeId: "",
      remark: "",
      attachId: guid(),
    };
    this.arrayCopy = [];
    this.getBillCode(5);
    this.$nextTick(() => {
      this.$refs["form"].clearValidate();
    });
  },
  methods: {
    tableRowClassName({ row }) {
      let diff = Number(row.quantity) - row.oldQuantity;
      if (diff < 0) {
        return "warning-row";
      } else if (diff > 0) {
        return "success-row";
      } else {
        return "";
      }
    },
    setStoreArray() {
      let storeId = this.form.storeId;
      if (storeId) {
        let loading = this.$loading({
          lock: true,
          text: "加载中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.$get("Inventory/GetStoreArray", {
          storeId,
          showAll: this.showAll,
        })
          .then((r) => {
            this.arrayCopy = r;
          })
          .finally(() => {
            loading.close();
          });
      } else {
        this.arrayCopy = [];
      }
    },
    save() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        let detail = [];
        let errorMsg = "";
        this.arrayCopy.forEach((item) => {
          let quantity = Number(item.quantity);
          if (quantity < 0) {
            errorMsg = "【" + item.goodsName + "】现有数量不能小于0";
          }
          if (item.oldQuantity != quantity) {
            detail.push({
              goodsId: item.goodsId,
              breakevenQuantity: quantity - item.oldQuantity,
            });
          }
        });
        if (errorMsg) {
          this.$message.error(errorMsg);
          return;
        }
        if (detail.length == 0) {
          this.$message.error("没有盘点明细");
          return;
        }
        let loading = this.$loading({
          lock: true,
          text: "保存中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let bill = { ...this.form };
        this.$post("Inventory/Add", {
          bill,
          detail,
        })
          .then((r) => {
            this.$message.success("操作成功！");
            this.$tabs.close();
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    cancel() {
      this.$g.deleteAttach(this.form.attachId);
      this.$tabs.close();
    },
  },
};
</script>

<style>
</style>